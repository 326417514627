import { ServiceFactory } from '@/services/ServiceFactory'
import moment from 'moment'
// const SettingsBookingService = ServiceFactory.get('SettingsBookingService')
const BookingScheduleService = ServiceFactory.get('BookingScheduleService')

export default {
  name: 'DashboardComponent',
  data () {
    return {
      loadingSlider: true,
      days: [
        {
          value: 0,
          text: 'Lunes'
        },
        {
          value: 1,
          text: 'Martes'
        },
        {
          value: 2,
          text: 'Miercoles'
        },
        {
          value: 3,
          text: 'Jueves'
        },
        {
          value: 4,
          text: 'Viernes'
        },
        {
          value: 5,
          text: 'Sábado'
        },
        {
          value: 6,
          text: 'Domingo'
        }
      ],
      // days: {
      //   0: 'Lunes',
      //   1: 'Martes',
      //   2: 'Miercoles',
      //   3: 'Jueves',
      //   4: 'Viernes',
      //   5: 'Sábado',
      //   6: 'Domingo'
      // },
      schedule: [],
      daysAvailable: [],
      hoursAvailable: [],
      day: moment().locale('es').weekday()
    }
  },
  mounted () {
    this.indexBookingSchedule()
  },
  methods: {
    async indexBookingSchedule () {
      // console.log()
      this.loadingSlider = true
      try {
        // const { success, schedule, daysAvailable, hoursAvailable } = await BookingScheduleService.index()
        const { success, schedule } = await BookingScheduleService.schedule(this.day)
        if (success) {
          this.schedule = schedule
          // this.daysAvailable = daysAvailable
          // this.hoursAvailable = hoursAvailable
        }
        setTimeout(() => {
          this.loadingSlider = false
        }, 500)
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
        this.loadingSlider = false
      }
    }
  }
}
